.employee {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;  
  &-vertCard {
    width: 100%;
    &-content {
      width: 100%;
      .box {
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &.restaurant {
        // background-image: url(../assets/images/homePage/BGvegetables.png);
        background-repeat: no-repeat;
        background-position: center right;
        padding-top: 200px;
        padding-bottom: 110px;
      }
      &.poroApp {
        // background-image: url(../assets/images/homePage/BGdish.png);
        background-repeat: no-repeat;
        background-position: center left;
        padding-top: 110px;
        padding-bottom: 230px;
      }

      &-title {
        color: #000;
        font-size: 48px;
        font-weight: 600;
        line-height: normal;
        max-width: 760px;
        width: 100%;
        text-align: center;
        margin: 0;
        font-family: Poppins, sans-serif;
      }
      &-text {
        color: #000;
        font-size: 24px;
        font-weight: 500;
        line-height: 38px;
        text-align: center;
        max-width: 760px;
        width: 100%;
        text-align: center;
        margin: 32px 0 52px 0;
        font-family: Montserrat, sans-serif;
      }
      &-btns {
        gap: 12px;
        display: flex;
      }
    }
  }

  &-horCard {
    width: 100%;
    padding-bottom: 70px;
  }
}



@media (max-width: 768px) {
  .employee {
    &-vertCard {
      &-content {
        &-title {
          font-size: 24px;
          font-weight: 600;
        }
        &-text {
          font-size: 14px;
          line-height: 24px;
          margin: 12px 0;
          max-width: 350px;
        }
        &.restaurant {
          background-size: 70px 200px;
          margin-top: 116px;

          padding: 0;
        }
        &.poroApp {
          background-size: 70px 200px;

          margin-bottom: 122px;
          padding: 0;
        }
      }
    }

    &-horCard {
      gap: "120px";
      padding-bottom: 30px;
    }
  }
}
