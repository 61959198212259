.home {
 display: flex;
 background: url(../assets/images/homePage/home_bg_1.jpg);
 background-size: 50%;
 background-size: cover ;
 background-position: center;
  // &-men{

  //   background-image: url(../assets//images/homePage/men1.jpg);
  //   background-size: cover;
  //   background-position: 50%;
  // }
  // &-women{
  //   background-image: url(../assets//images/homePage/men1.jpg);
  //   background-size: cover;
  //   background-position: 50%;
  // }
  .title {
    font-family: Poppins, sans-serif;
  }
  .text {
    font-family: Montserrat, sans-serif;
  }
}
