.horizontalCard {
  padding: 0 50px;
  gap: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 1200px;
  &-image {
    width: 100%;
    max-width: 480px;
    height: auto;
    max-height: 500px;
    border-radius: 25px 100px;
  }
  &-mobileTitle {
    display: none;
    font-family: Poppins, sans-serif;
  }
  &-content {
    border-radius: 24px;
    max-width: 616px;
    height: fit-content;

    &-title {
      color: #000;
      font-size: 48px;
      font-weight: 600;
      line-height: normal;
      margin: 0;
      font-family: Poppins, sans-serif;
    }

    &-description {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 38px;
      padding: 32px 0;
      margin: 0;
      font-family: Montserrat, sans-serif;
    }
    &-btns {
      display: flex;
      justify-content: flex-start;
      gap: 12px;
    }
  }
}
@media (max-width: "1023px") {
  .horizontalCard {
    padding: 0 20px;
    flex-direction: column;
    gap: 0;
    &-mobileTitle {
      display: block;
      font-size: 21px;
      font-weight: 700;
    }
    &-content {
      text-align: center;
      &-title {
        display: none;
      }
      &-description {
        font-size: 17px;
      }
      &-btns {
        justify-content: center;
      }
    }
  }
}

@media (max-width: "768px") {
  .horizontalCard {
    padding: 0 20px;
    flex-direction: column;
    &-image {
      max-width: 304px;
      margin: 32px 0;
    }
    &-mobileTitle {
      margin: 0;
      font-size: 24px;
      line-height: normal;
    }
    &-content {
      text-align: center;

      &-description {
        font-size: 14px;
        max-width: 380px;
        line-height: 24px;
        padding: 0;
        margin-bottom: 14px;
      }

      &-btns {
        justify-content: center;
      }
    }
  }
}
