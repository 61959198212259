@import "_header.scss";
@import "_homeComponent.scss";
@import "_horizontalCard.scss";
@import "_employee.scss";
@import "_footer.scss";
@import "_globalStyle.scss";
@import "_map.scss";


.image-item {

  transform: rotateY(90deg);
}

.image-item.fadeInRotate {
  animation: fadeInRotate 0.5s linear forwards;
}

@keyframes fadeInRotate {
  0% {
 
    transform: rotateY(90deg);
  }
  100% {

    transform: rotateY(0);
  }
}
