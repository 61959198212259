.header {
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 6px 6px 0px rgba(0, 0, 0, 0.09);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  &-logo{
    position: relative;
    font-size: 30px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    color: white;
    text-decoration: none;
    &:hover{
      color: orange;
    }
    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: orange;
      transition: all 0.3s ease; 
      transition-timing-function: "linear",
    }
    &:hover::after {
      width: 100%;
      color: red;
    }

  }
  &-link {
    position: relative;
    text-decoration: none;
    color: white;
    padding: 8px 10px;
    font-size: 25px;
    font-style: normal;
    font-weight: bold ;
    font-family:'Times New Roman', Times, serif;
    cursor: pointer;
    &:hover{
      color: orange;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: orange;
      transition: width 0.3s ease; 
      transition-timing-function: "linear",
    }
    &:hover::after {
      width: 100%;
    }

  }
}

.mobile {
  background: black;
  height:100vh;
  &-list {
    padding-top: 100px;
    padding-left: 28px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: flex-start;
    &:hover{
      color: 'orange';
    }
    // max-height: 0;
    // // overflow: hidden;
    // // background: red;
    // // height: 0;
    // transform: translateX(-100%);
    // transition: all 0.5s ease-in-out;
    &.open {
      // transform: translate(0);
      // height: 100%;
      // max-height: 700px;
      // overflow: visible;
    }
  }
}
