.footer {
  width: 100%;
  &-container {
    padding: 100px 0 42px 0;
    display: flex;
    justify-content: space-between;
    margin: 0 120px;
    border-bottom: 1px solid black;
    &-box {
      display: flex;
      flex-direction: column;
      &-title {
        color: #342f29;
        font-size: 18px;
        font-weight: 600;
        line-height: normal;
        font-family: Poppins, sans-serif;
      }
      &-link {
        color: #78716a;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-decoration: none;
        font-family: Montserrat, sans-serif;
      }
    }
  }

  &-authorRights {
    padding: 42px 120px;
    display: flex;
    justify-content: space-between;
    &-text {
      color: #78716a;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-family: Montserrat, sans-serif;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &-container {
      padding: 24px 0 42px;
      margin: 0 24px;
      &-box {
        max-width: 108px;

        &-link {
          font-size: 14px;
          line-height: normal;
          margin-bottom: 10px;
        }
      }
    }
    &-authorRights {
      padding: 42px 24px;
    }
  }
}
